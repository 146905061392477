import { Component, Inject, NgZone, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [MatButtonModule],
})
export class LoginComponent implements OnInit {
  isAuthenticated!: boolean;
  error?: Error;
  ngZone: NgZone = inject(NgZone);

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private router: Router
  ) {}

  async ngOnInit() {
    console.log(environment.env);
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    this.ngZone.run(
      () => this.isAuthenticated && this.router.navigateByUrl('/revenue-management')
    );
  }

  async onSignIn() {
    try {
      await this.oktaAuth.signInWithRedirect();
    } catch (err: Error | unknown) {
      console.error(err);
    }
  }
}
