import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserInfoService } from '../services/user-info.service';
import { APP_BASE_URL } from '../app.module';

@Injectable()
export class UserDetailsInterceptorService implements HttpInterceptor {
  private userInfoService = inject(UserInfoService);
  private readonly APP_BASE_URL = inject(APP_BASE_URL);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const userClaims = this.userInfoService.userInfoDetails;
    let clonedRequest = req;
    if(req.url.includes(this.APP_BASE_URL)){
      clonedRequest = req.clone({
        setHeaders: {
          'X-User-Email': userClaims.email || ''
        }
      });
    }
  
    return next.handle(clonedRequest);
  }
}