import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { filter, noop, tap } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { UserInfoService } from '../../services/user-info.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() showSideNav = false;

  userInfoService = inject(UserInfoService);
  navigationService = inject(NavigationService);
  router = inject(Router);
  isSideNavOpen = true;
  initials!: string;
  userName = '';

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) {}

  ngOnInit() {
    this.userInfoService.isUserInfoUpdated$
      .pipe(
        filter((res: boolean) => !!res),
        tap({
          next: () => {
            const userClaims = this.userInfoService.userInfoDetails;
            this.initials = `${userClaims.name?.charAt(0)} ${userClaims.family_name?.charAt(0)}`;
            this.userName = userClaims?.given_name
              ? userClaims?.given_name
              : '';
          },
        })
      )
      .subscribe(noop);
  }

  public openSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
    this.navigationService.isToggleSideNav$.next(this.isSideNavOpen);
  }

  async logout() {
    await this.oktaAuth.signOut();
    this.oktaAuth.tokenManager.clear();
    this.router.navigate(['/login']);
  }
}
