export enum OrientationType {
  Unknown = 'Unknown',
  Portrait = 'Portrait',
  Landscape = 'Landscape',
}

export enum DeviceType {
  Unknown = 'Unknown',
  Tablet = 'Tablet',
  Handset = 'Handset', //Mobile
  Web = 'Web',
}

export enum ScreenSizeType {
  Unknown = 'Unknown',
  XSmall = 'XSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
}

export enum BreakpointType {
  Unknown = 'Unknown',

  HandsetPortrait = 'HandsetPortrait',
  HandsetLandscape = 'HandsetLandscape',

  TabletPortrait = 'TabletPortrait',
  TabletLandscape = 'TabletLandscape',

  WebPortrait = 'WebPortrait',
  WebLandscape = 'WebLandscape',
}
