import { InjectionToken, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationModule } from './components/navigation/navigation.module';
import { AuthInterceptor } from './interceptors/auth-interceptor.service';
import { ErrorInterceptor } from './interceptors/error-interceptor.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { TokenResolver } from './resolver/token.resolver';
import { UserDetailsInterceptorService } from './interceptors/user-details-interceptor.service';
import { CookieService } from 'ngx-cookie-service';

const oktaAuth = new OktaAuth({
  issuer: environment.OKTAIssuer,
  clientId: environment.OKTAClientID,
  redirectUri: environment.OKTArediRectUri,
});

export const APP_BASE_URL = new InjectionToken<string>('app.baseUrl');
export const CORE_DATA_URL = new InjectionToken<string>('app.coreDataURL');
export const RM_COOKIE_NAME = new InjectionToken<string>('RM tool cookie name');
export const ENVIRONMENT_NAME = new InjectionToken<string>('Environment');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HeaderComponent,
    NavigationModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    provideHttpClient(withInterceptors([AuthInterceptor])),
    CookieService,
    TokenResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserDetailsInterceptorService,
      multi: true,
    },
    { provide: APP_BASE_URL, useValue: environment.baseURL },
    { provide: CORE_DATA_URL, useValue: environment.coreDataURL },
    { provide: RM_COOKIE_NAME, useValue: environment.rmToolCookieName },
    { provide: ENVIRONMENT_NAME, useValue: environment.env },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
